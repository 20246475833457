import Head from "next/head";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import type { AppProps } from "next/app";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { Inter, Noto_Sans_SC } from "next/font/google";
import "../styles/globals.css";
import { appWithTranslation, useTranslation } from "next-i18next";
import { theme } from "@/theme/theme";
import { useEffect, useState } from "react";
import { getBrowserLanguage } from "@/utils";
import { useRouter } from "next/router";
import { fetchCurrencyRates } from "@/api";
import { FALLBACK_RATES } from "@/constants";

const interFont = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  display: "swap",
  adjustFontFallback: false,
});

const notoSansSc = Noto_Sans_SC({
  subsets: ["latin"],
  variable: "--font-noto-sans-sc",
  display: "optional",
});

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const { i18n } = useTranslation();
  const [cid, setCid] = useState<string | string[]>("");
  const [referral, setReferral] = useState<string | string[]>("");

  /**
   * Handle setting currency rates based on rates returned by API.
   */
  useEffect(() => {
    fetchCurrencyRates()
      .then((res) => {
        if (res?.success) {
          sessionStorage.setItem("rates", JSON.stringify(res.rates));
        } else {
          sessionStorage.setItem("rates", JSON.stringify(FALLBACK_RATES));
        }
      })
      .catch((err) => {
        sessionStorage.setItem("rates", JSON.stringify(FALLBACK_RATES));
      });
  }, []);

  /**
   * If cid or referral values exist, append in URL.
   */
  useEffect(() => {
    if (query.cid) {
      setCid(query.cid);
    }

    if (query.via) {
      setReferral(query.via);
    }
  }, [query.cid, query.via]);

  useEffect(() => {
    if (cid && cid !== query.cid) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, cid: cid },
      });
    }

    if (referral && referral !== query.via) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, via: referral },
      });
    }
  }, [cid, query.cid, query.via, referral, router]);

  /**
   * Handle setting default site language.
   */
  useEffect(() => {
    const savedLocale =
      typeof window !== "undefined" ? sessionStorage.getItem("locale") : "";
    const routerLocale = router.locale;
    const browserLanguage = getBrowserLanguage();

    if (!savedLocale && browserLanguage !== "en" && routerLocale === "en") {
      router
        .push({ pathname, query }, asPath, { locale: browserLanguage })
        .then(() => {
          router.reload();
        });
    }
    sessionStorage.setItem("locale", routerLocale as string);
  }, [asPath, pathname, query, router, router.locale]);

  const generateUrl = (type: string) => {
    if (asPath === "/" || asPath === "/404") {
      return type === "canonical"
        ? i18n.language === "en"
          ? ""
          : `/${i18n.language}`
        : "";
    }
    return type === "canonical"
      ? i18n.language !== "en"
        ? `/${i18n.language + asPath}`
        : asPath
      : asPath;
  };

  return (
    <MantineProvider theme={theme}>
      <main
        className={
          ["zh-cn", "zh-tw"].includes(i18n.language)
            ? notoSansSc.className
            : interFont.className
        }
        style={{
          isolation: "isolate",
        }}
      >
        <Notifications />
        <Head>
          <title>Japan Bullet Train</title>
          <link
            href={`https://www.japan-bullettrain.com${generateUrl(
              "canonical",
            )}`}
            rel="canonical"
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`https://www.japan-bullettrain.com${generateUrl(
              "alternate",
            )}`}
          />
          <link
            rel="alternate"
            hrefLang="zh-Hant"
            href={`https://www.japan-bullettrain.com/zh-cn${generateUrl(
              "alternate",
            )}`}
          />
          <link
            rel="alternate"
            hrefLang="zh-Hans"
            href={`https://www.japan-bullettrain.com/zh-tw${generateUrl(
              "alternate",
            )}`}
          />
          <link
            rel="alternate"
            hrefLang="ko"
            href={`https://www.japan-bullettrain.com/ko${generateUrl(
              "alternate",
            )}`}
          />
          <link
            rel="alternate"
            hrefLang="es"
            href={`https://www.japan-bullettrain.com/es${generateUrl(
              "alternate",
            )}`}
          />
          <link
            rel="alternate"
            hrefLang="fr"
            href={`https://www.japan-bullettrain.com/fr${generateUrl(
              "alternate",
            )}`}
          />
          <link
            rel="alternate"
            hrefLang="id"
            href={`https://www.japan-bullettrain.com/id${generateUrl(
              "alternate",
            )}`}
          />
        </Head>
        <Component {...pageProps} />
      </main>
    </MantineProvider>
  );
}

export default appWithTranslation(App);
